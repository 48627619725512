import React from "react";
import { useState, useEffect } from "react";
import cryptoRandomString from "crypto-random-string";
import { useLocation, useNavigate } from "react-router-dom";
import { useFirestore } from "../hooks/useFirestore";
import { Spin, Alert } from "antd";
import {
  IoPeople,
  IoCalendarSharp,
  IoTransgenderSharp,
  IoMailSharp,
  IoCallSharp,
  IoAirplaneSharp,
} from "react-icons/io5";
import "./TransferDetails.css";
import Mercoflipped1 from "../assets/mercoflipped1.png";
import Sprinterflipped from "../assets/sprinterflipped.png";
import "bootstrap/dist/css/bootstrap.min.css";
import UseEmail from "../components/UseEmail";
import i18next, { use } from "i18next";
import { useTranslation } from "react-i18next";

export default function TransferDetails() {
  const { t, i18n } = useTranslation();
  const { sendEmail } = UseEmail(
    "https://public.herotofu.com/v1/3b5f5b40-6372-11ed-891b-4f350712a1f0"
  );

  const sendExample = () => {
    sendEmail({
      Title: cTitle,
      Name: cName,
      Surname: cSurname,
      "Pick Up Location": cPickupLocation,
      "Drop Off Location": cDropoffLocation,
      Adult: cAdult,
      Children: cChildren,
      Date: cDate,
      Direction: cDirection,
      "Transfer Type": cTransfertype,
      "Return Pick Up Location": cReturnPickupLocation,
      "Return Drop off Location": cReturnDropoffLocation,
      "Return Date": cReturnDate,
      Email: cEmail,
      Phone: cPhone,
      "Flight Number": cFlightnumber,
      "Detailed Adress": cAdress,
      "Reservation Code": cReservationcode,
      "Total Price": transferPrice,
      "Reservation Time": datetime,
    });
  };
  const [privatePrice, setPrivatePrice] = useState("");
  const [vitoPrice, setVitoPrice] = useState("");
  const [sprinterPrice, setSprinterPrice] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [cTransfertype, setcTransfertype] = useState("");
  const [cTitle, setcTitle] = useState("Mr");
  const [cName, setcName] = useState("");
  const [cSurname, setcSurname] = useState("");
  const [cEmail, setcEmail] = useState("");
  const [cPhone, setcPhone] = useState("");
  const [cAdress, setcAdress] = useState("");
  const [cFlightnumber, setcFlightnumber] = useState("");
  const [transferPrice, setTransferPrice] = useState("");

  const cReservationcode = cryptoRandomString({
    length: 10,
    type: "alphanumeric",
  });

  const { addDocument, response } = useFirestore("transfers");
  const navigate = useNavigate();
  const [active, setActive] = useState(false);

  var currentdate = new Date();
  var datetime =
    currentdate.getDate() +
    "/" +
    (currentdate.getMonth() + 1) +
    "/" +
    currentdate.getFullYear() +
    " @ " +
    currentdate.getHours() +
    ":" +
    currentdate.getMinutes() +
    ":" +
    currentdate.getSeconds();

  var elmntToView = document.getElementById("rezform3");
  const privatebutton = document.getElementById("private");
  const mercedesbutton = document.getElementById("mercedes");
  const passengervanbutton = document.getElementById("passengervan");

  const { state } = useLocation();

  const {
    cDate,
    cPickupLocation,
    cDropoffLocation,
    cReturnPickupLocation,
    cReturnDropoffLocation,
    cReturnDate,
    cChildren,
    cAdult,
    cDirection,
  } = state;

  useEffect(() => {
    switch (true) {
      case cPickupLocation === "ALANYA" &&
        cDropoffLocation === "ANTALYA AIRPORT(AYT)":
        setPrivatePrice("60€");
        setVitoPrice("80€");
        setSprinterPrice("80€");
        break;
      case cPickupLocation === "ANTALYA AIRPORT(AYT)" &&
        cDropoffLocation === "ALANYA":
        setPrivatePrice("60€");
        setVitoPrice("80€");
        setSprinterPrice("80€");
        break;
      case cPickupLocation === "ALANYA" &&
        cDropoffLocation === "GAZIPAŞA AIRPORT(GZP)":
        setPrivatePrice("30€");
        setVitoPrice("30€");
        setSprinterPrice("50€");
        break;
      case cPickupLocation === "GAZIPAŞA AIRPORT(GZP)" &&
        cDropoffLocation === "ALANYA":
        setPrivatePrice("30€");
        setVitoPrice("30€");
        setSprinterPrice("50€");
        break;
      case cPickupLocation === "ALANYA" && cDropoffLocation === "SIDE":
        setPrivatePrice("50€");
        setVitoPrice("50€");
        setSprinterPrice("70€");
        break;
      case cPickupLocation === "SIDE" && cDropoffLocation === "ALANYA":
        setPrivatePrice("50€");
        setVitoPrice("50€");
        setSprinterPrice("70€");
        break;
      case cPickupLocation === "ALANYA" && cDropoffLocation === "BELEK":
        setPrivatePrice("60€");
        setVitoPrice("60€");
        setSprinterPrice("80€");
        break;
      case cPickupLocation === "BELEK" && cDropoffLocation === "ALANYA":
        setPrivatePrice("60€");
        setVitoPrice("60€");
        setSprinterPrice("80€");
        break;
      case cPickupLocation === "OKURCALAR" &&
        cDropoffLocation === "GAZIPAŞA AIRPORT(GZP)":
        setPrivatePrice("60€");
        setVitoPrice("60€");
        setSprinterPrice("80€");
        break;
      case cPickupLocation === "GAZIPAŞA AIRPORT(AYT)" &&
        cDropoffLocation === "OKURCALAR":
        setPrivatePrice("60€");
        setVitoPrice("60€");
        setSprinterPrice("80€");
        break;
      case cPickupLocation === "AVSALLAR" &&
        cDropoffLocation === "GAZIPAŞA AIRPORT(GZP)":
        setPrivatePrice("50€");
        setVitoPrice("50€");
        setSprinterPrice("70€");
        break;
      case cPickupLocation === "GAZIPAŞA AIRPORT(GZP)" &&
        cDropoffLocation === "AVSALLAR":
        setPrivatePrice("50€");
        setVitoPrice("50€");
        setSprinterPrice("70€");
        break;
      case cPickupLocation === "TÜRKLER" &&
        cDropoffLocation === "GAZIPAŞA AIRPORT(GZP)":
        setPrivatePrice("50€");
        setVitoPrice("50€");
        setSprinterPrice("70€");
        break;
      case cPickupLocation === "GAZIPAŞA AIRPORT(GZP)" &&
        cDropoffLocation === "TÜRKLER":
        setPrivatePrice("50€");
        setVitoPrice("50€");
        setSprinterPrice("70€");
        break;
      case cPickupLocation === "KONAKLI" &&
        cDropoffLocation === "GAZIPAŞA AIRPORT(GZP)":
        setPrivatePrice("40€");
        setVitoPrice("40€");
        setSprinterPrice("60€");
        break;
      case cPickupLocation === "GAZIPAŞA AIRPORT(GZP)" &&
        cDropoffLocation === "KONAKLI":
        setPrivatePrice("40€");
        setVitoPrice("40€");
        setSprinterPrice("60€");
        break;
      default:
        console.log("NO PRICE FOUND FOR THE SELECTED TRANSFER");
    }
  });

  useEffect(() => {
    if (cTransfertype === "Mercedes Sprinter") {
      mercedesbutton.style.backgroundColor = active ? "#ffdd40" : "#001529";
      mercedesbutton.style.color = active ? "black" : "#ffdd40";
      mercedesbutton.textContent = active ? "SELECTED" : "SELECT";
      setTransferPrice(sprinterPrice);

      active
        ? (passengervanbutton.disabled = true)
        : (passengervanbutton.disabled = false);
      active
        ? (privatebutton.disabled = true)
        : (privatebutton.disabled = false);
      elmntToView.scrollIntoView({ behavior: "smooth" });
    } else if (cTransfertype === "Private Car") {
      privatebutton.style.backgroundColor = active ? "#ffdd40" : "#001529";
      privatebutton.style.color = active ? "black" : "#ffdd40";
      privatebutton.textContent = active ? "SELECTED" : "SELECT";
      setTransferPrice(privatePrice);
      active
        ? (passengervanbutton.disabled = true)
        : (passengervanbutton.disabled = false);
      active
        ? (mercedesbutton.disabled = true)
        : (mercedesbutton.disabled = false);
      elmntToView.scrollIntoView({ behavior: "smooth" });
    } else if (cTransfertype === "Passenger Van") {
      passengervanbutton.style.backgroundColor = active ? "#ffdd40" : "#001529";
      passengervanbutton.style.color = active ? "black" : "#ffdd40";
      passengervanbutton.textContent = active ? "SELECTED" : "SELECT";
      setTransferPrice(vitoPrice);
      active
        ? (privatebutton.disabled = true)
        : (privatebutton.disabled = false);
      active
        ? (mercedesbutton.disabled = true)
        : (mercedesbutton.disabled = false);
      elmntToView.scrollIntoView({ behavior: "smooth" });
    }
  }, [
    cTransfertype,
    active,
    mercedesbutton,
    privatebutton,
    passengervanbutton,
    elmntToView,
    cDropoffLocation,
    cPickupLocation,
  ]);

  const handleTransfertype = async (e) => {
    setActive(!active);
    setcTransfertype(e.target.value);
    setTransferPrice(e.target.value);
  };

  //HANDLESUBMIT//
  const handlerezSubmit = async (e) => {
    e.preventDefault();

    if (cTransfertype === "") {
      setError("You must pick your Transfer Type");
      throw new Error("You must pick your Transfer Type");
    }
    try {
      setIsPending(true);
      setError(null);

      const res = await addDocument({
        cPickupLocation,
        cDropoffLocation,
        cDate,
        cChildren,
        cAdult,
        cDirection,
        cReturnDropoffLocation,
        cReturnPickupLocation,
        cReturnDate,
        cTitle,
        cTransfertype,
        cName,
        cSurname,
        cEmail,
        cFlightnumber,
        cPhone,
        cAdress,
        cReservationcode,
        transferPrice,
        datetime,
      });
      setIsPending(false);
      navigate("/successfultransfer", {
        state: {
          cReservationcode,
          cPickupLocation,
          cDropoffLocation,
          cDate,
          cChildren,
          cAdult,
          cDirection,
          cReturnPickupLocation,
          cReturnDropoffLocation,
          cReturnDate,
          cTitle,
          cTransfertype,
          cName,
          cSurname,
          cEmail,
          cFlightnumber,
          cPhone,
          cAdress,
          transferPrice,
        },
      });
    } catch (err) {
      setIsPending(false);
      setError("An error ocured while making your reservation");
      throw new Error("An error ocured while making your reservation");
    }
    sendExample();
  };

  useEffect(() => {
    if (response.success) {
      setcTransfertype("");
      setcTitle("");
      setcName("");
      setcSurname("");
      setcEmail("");
      setcPhone("");
      setcAdress("");
      setcFlightnumber("");
      setTransferPrice("");
      setVitoPrice("");
      setPrivatePrice("");
      setSprinterPrice("");
      setcTransfertype("");
    }
  }, [response.success]);

  return (
    <div className="main">
      <div className="transfer-form-2" required>
        <div className="wrap">
          <div className="row" id="result">
            <div className="content full-width search-results-top">
              <h2 className="form-overhead"> {t("Select your vehicle")}</h2>
              <div className="results">
                <article className="result" id="result1" autoFocus>
                  <div
                    className="one-fourth heightfix"
                    style={{ height: 203.48 }}
                  >
                    <img
                      src="https://www.themeenergy.com/themes/wordpress/transfers/wp-content/uploads/2015/06/car.jpg"
                      alt="Car1"
                    />
                  </div>
                  <div className="one-half" style={{ height: 203.48 }}>
                    <h3>{t("Private Car")}</h3>
                    <ul>
                      <li>
                        <span className="second-form-icon">
                          <IoPeople />
                        </span>
                        <p>
                          Max <strong>{t("4 people")}</strong>
                        </p>
                      </li>
                      <li>
                        <span className="second-form-icon">
                          <IoCalendarSharp />
                        </span>
                        <p>
                          {t("Departure date")}
                          <br />
                          <strong>{cDate}</strong>
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="one-fourth" style={{ height: 203.48 }}>
                    <div>
                      <div className="price">{privatePrice}</div>
                      <span className="meta">{t("per vehicle")}</span>
                      <button
                        id="private"
                        value={"Private Car"}
                        onClick={handleTransfertype}
                        className="btn large"
                      >
                        {t("select")}
                      </button>
                    </div>
                  </div>
                </article>
                <article className="result" id="result2">
                  <div className="one-fourth" style={{ height: 203.48 }}>
                    <img src={Mercoflipped1} alt="Car2" />
                  </div>
                  <div className="one-half" style={{ height: 203.48 }}>
                    <h3>Mercedes VITO</h3>
                    <ul>
                      <li>
                        <span className="second-form-icon">
                          <IoPeople />
                        </span>
                        <p>
                          Max <strong>{t("6 people")}</strong>
                        </p>
                      </li>
                      <li>
                        <span className="second-form-icon">
                          <IoCalendarSharp />
                        </span>
                        <p>
                          {t("Departure date")}
                          <br />
                          <strong>{cDate}</strong>
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="one-fourth" style={{ height: 203.48 }}>
                    <div>
                      <div className="price">{vitoPrice}</div>
                      <span className="meta">{t("per vehicle")}</span>
                      <button
                        autoFocus
                        id="passengervan"
                        value={"Passenger Van"}
                        onClick={handleTransfertype}
                        className="btn large"
                      >
                        {t("select")}
                      </button>
                    </div>
                  </div>
                </article>
                <article className="result" id="result3">
                  <div className="one-fourth" style={{ height: 186 }}>
                    <img src={Sprinterflipped} alt="Car3" />
                  </div>
                  <div className="one-half" style={{ height: 203.48 }}>
                    <h3>MERCEDES SPRINTER</h3>
                    <ul>
                      <li>
                        <span className="second-form-icon">
                          <IoPeople />
                        </span>
                        <p>
                          Max <strong>{t("17 People")}</strong>
                        </p>
                      </li>
                      <li>
                        <span className="second-form-icon">
                          <IoCalendarSharp />
                        </span>
                        <p>
                          {t("Departure date")}
                          <br />
                          <strong>{cDate}</strong>
                        </p>
                      </li>
                    </ul>
                  </div>

                  <div className="one-fourth" style={{ height: 203.4 }}>
                    <div>
                      <div className="price">{sprinterPrice}</div>
                      <span className="meta">{t("per vehicle")}</span>

                      <button
                        id="mercedes"
                        value={"Mercedes Sprinter"}
                        onClick={handleTransfertype}
                        className="btn grey large select-avail-slot select-avail-slot-time-1020 select-avail-dep-slot "
                      >
                        {t("select")}
                      </button>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>

          {error && (
            <Alert
              message="Error!"
              description={error}
              type="error"
              id="alert"
            />
          )}

          <form
            className="quick-reservation"
            id="rezform3"
            onSubmit={handlerezSubmit}
          >
            <div className="container">
              <h2 className="title">{t("Personal Information")}</h2>
              <div className="quick-reservation__form">
                <section className="form__content">
                  <div className="row-wrapper">
                    <div className="ele adults">
                      <label htmlFor="title">{t("Title")}</label>
                      <div className="input-container">
                        <i className="fa icon">
                          {" "}
                          <IoTransgenderSharp />
                        </i>

                        <select
                          name="title"
                          onChange={(e) =>
                            setcTitle(e.target.value.toUpperCase())
                          }
                          required
                        >
                          <option value="Mr.">{t("Mr.")}</option>
                          <option value="Mrs.">{t("Ms/Mrs.")}</option>
                        </select>
                      </div>
                    </div>
                    <div className="ele first-name">
                      <label htmlFor="firstName">{t("First Name")}</label>
                      <div className="input-container">
                        <i className="fa fa-user icon"></i>
                        <input
                          required
                          type="text"
                          value={cName}
                          onChange={(e) =>
                            setcName(e.target.value.toUpperCase())
                          }
                          placeholder={t("First Name")}
                          id="firstName"
                        />
                      </div>
                    </div>
                    <div className="ele last-name">
                      <label htmlFor="lastName">{t("Last Name")}</label>
                      <div className="input-container">
                        <i className="fa fa-user icon  "></i>
                        <input
                          required
                          type="text"
                          value={cSurname}
                          onChange={(e) =>
                            setcSurname(e.target.value.toUpperCase())
                          }
                          placeholder={t("Last Name")}
                          id="lastName"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row-wrapper">
                    <div className="ele email-address">
                      <label htmlFor="emailAddress">{t("Email Address")}</label>
                      <div className="input-container">
                        <i className="fa icon">
                          {" "}
                          <IoMailSharp />
                        </i>
                        <input
                          required
                          type="email"
                          value={cEmail}
                          onChange={(e) => setcEmail(e.target.value)}
                          placeholder={t("Enter Email")}
                          id="emailAddress"
                        />
                      </div>
                    </div>
                    <div className="ele phone-number">
                      <label htmlFor="phoneNumber">{t("Phone Number")}</label>
                      <div className="input-container">
                        <i className="fa icon">
                          {" "}
                          <IoCallSharp />
                        </i>
                        <input
                          required
                          type="text"
                          value={cPhone}
                          onChange={(e) =>
                            setcPhone(e.target.value.toUpperCase())
                          }
                          placeholder={t("Phone Number")}
                          id="phoneNumber"
                        />
                      </div>
                    </div>
                    <div className="ele phone-number">
                      <label htmlFor="phoneNumber">{t("Flight Number")}</label>
                      <div className="input-container">
                        <i className="fa icon">
                          {" "}
                          <IoAirplaneSharp />
                        </i>
                        <input
                          required
                          type="text"
                          value={cFlightnumber}
                          onChange={(e) =>
                            setcFlightnumber(e.target.value.toUpperCase())
                          }
                          placeholder={t("Flight Number")}
                          id="flightNumber"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row-wrapper more-options">
                    <label htmlFor="Notes...">
                      {t("Detailed Pickup Place")}
                    </label>

                    <textarea
                      required
                      placeholder={t(
                        "Where exactly should we pick you up from?"
                      )}
                      rows="6"
                      cols="70"
                      value={cAdress}
                      onChange={(e) => setcAdress(e.target.value.toUpperCase())}
                    />
                  </div>
                </section>
              </div>

              <div className="reservation-info">
                <div className="ele data">
                  <h4 className="data__head">{t("Transfer Type")}</h4>
                  <p className="data__description">{cTransfertype}</p>
                </div>
                <div className="ele data">
                  <h4 className="data__head">{t("Date")}</h4>
                  <p className="data__description">{cDate}</p>
                </div>
                <div className="ele data">
                  <h4 className="data__head">{t("From")}</h4>
                  <p className="data__description">{cPickupLocation}</p>
                  <h4 className="data__head">{t("To")}</h4>
                  <p className="data__description">{cDropoffLocation}</p>
                  <h4 className="data__head">{t("Price")}</h4>
                  <p className="data__description">{transferPrice}</p>
                </div>
              </div>

              <footer className="form__footer">
                <div className="footer-wrapper">
                  {isPending ? (
                    <Spin size="middle" />
                  ) : (
                    <input id="check-in" type="submit" value={t("Check-In")} />
                  )}
                </div>
              </footer>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
