import React from "react";
import "./Contact.css";
import Sidelogo from "../assets/facepp2.png";
import emailjs from "@emailjs/browser";
import { useRef, useState } from "react";
import { Alert } from "antd";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export default function Contact() {
  const [emailSuccess, setemailSuccess] = useState(false);
  const { t, i18n } = useTranslation();
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "gmail",
        "07alanyatravel_contact",
        form.current,
        "Ok_c7w3NCdAiZdCeh"
      )

      .then(
        (result) => {
          console.log(result.text);
          setemailSuccess(true);
        },
        (error) => {
          console.log(error.text);
          setemailSuccess(false);
        }
      );

    e.target.reset();
  };

  return (
    <div>
      <section className="ftco-section">
        <div className="contact-form-container">
          <div className="row justify-content-center"></div>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="wrapper">
                <div className="row mb-5"></div>
                <div className="row no-gutters">
                  <div className="col-md-7">
                    <div className="contact-wrap w-100 p-md-5 p-4">
                      <h3 className="mb-4">{t("Contact Us")}</h3>
                      <div id="form-message-warning" className="mb-4"></div>
                      <div id="form-message-success" className="mb-4">
                        Your message was sent, thank you!
                      </div>

                      <form
                        ref={form}
                        onSubmit={sendEmail}
                        method="POST"
                        id="contactForm"
                        name="contactForm"
                        className="contactForm"
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="label" alt="name">
                                {t("Full Name")}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="name"
                                id="name"
                                placeholder={t("Full Name")}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="label" alt="email">
                              {t("Email Address")}
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                id="email"
                                placeholder="Email"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="label" alt="subject">
                              {t("Subject")}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="subject"
                                id="subject"
                                placeholder={t("Subject")}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="label" alt="#">
                              {t("Message")}
                              </label>
                              <textarea
                                name="message"
                                className="form-control"
                                id="message"
                                cols="30"
                                rows="4"
                                placeholder={t("Message")}
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                type="submit"
                                value={t("Send Message")}
                                className="btn btn-primary"
                              />
                              {emailSuccess && (
                                <Alert
                                  message="Email sent"
                                  description={t("Your message has successfully been sent")}
                                  type="success"
                                  showIcon
                                />
                              )}
                              <div className="submitting"></div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div >
                    <img className="info-wrap" alt="sidelogo" src={Sidelogo} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
