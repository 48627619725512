import { Route, BrowserRouter, Routes } from "react-router-dom";
import { Suspense } from "react";
import "./App.css";
import Home from "./pages/Home";
import Admin from "./pages/Admin";
import Rentacar from "./pages/Rentacar";
import Tours from "./pages/Tours";
import Contact from "./pages/Contact";
import { MainLayout } from "./components/MainLayout/MainLayout";
import "bootstrap/dist/css/bootstrap.min.css";
import TransferDetails from "./pages/TransferDetails";
import SuccessfulTransfer from "./pages/SuccessfulTransfer";
import { useAuthContext } from "./hooks/useAuthContext";
import Login from "./pages/login/Login";
import AboutUs from "./pages/AboutUs";

function App() {
  const { authIsReady, user } = useAuthContext();
  return (
    <div className="App">
      <Suspense fallback="Loading...">
        {authIsReady && (
          <BrowserRouter>
            <MainLayout>
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/rentacar" element={<Rentacar />} />
                <Route path="/tours" element={<Tours />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/admin" element={!user ? <Home /> : <Admin />} />
                <Route path="/transferdetails" element={<TransferDetails />} />
                <Route
                  path="/successfultransfer"
                  element={<SuccessfulTransfer />}
                />
                <Route path="/login" element={<Login />} />
                <Route path="/aboutus" element={<AboutUs/>}/>
              </Routes>
            </MainLayout>
          </BrowserRouter>
        )}
      </Suspense>
    </div>
  );
}

export default App;
