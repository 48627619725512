import styles from "./Login.module.css";
import { useState } from "react";
import { useLogin } from "../../hooks/useLogin";
import {useNavigate } from "react-router-dom";
export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, error, isPending } = useLogin();
const navigate = useNavigate()
  const handleSubmit = (e) => {
    e.preventDefault();
    login(email, password);
    navigate("/admin")
  };
  return (
    // tire javascript fonksiyonlarında - eksi olarak alındığı için login-form tırnak içerisinde string olarak verilir
    <form onSubmit={handleSubmit} className={styles["login-form"]}>
      <h2>Login</h2>
      <label>
        <span>email:</span>
        <input
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
      </label>
      <label>
        <span>password:</span>
        <input
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
      </label>

      {!isPending && <button className={styles.loginbutton}>Login</button>}
      {isPending && (
        <button className={styles.loginbutton} disabled>
          loading
        </button>
      )}
      {error && <p>{error}</p>}
    </form>
  );
}
