import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import tursablogo from "../assets/tursab-dds-11885.png"
import tursablogoen from "../assets/tursab-dvs-11885.png"
export default function Footercomp() {
  const { t, i18n } = useTranslation();
  return (
    <div className="footer">
      <div className="container">
        <div className="rowz">
          <div className="footer-col">
            <h4>{t("Company")}</h4>
            <ul>
              <li>
                <a href="/aboutus">{t("About Us")}</a>
              </li>
              <li>
                <a href="/rentacar">{t("Rent a Car")}</a>
              </li>
              <li>
                <a href="/tours">{t("Tours")}</a>
              </li>
              <li>
                <a href="/contact">{t("Contact Us")}</a>
              </li>
            </ul>
          </div>

          <div className="footer-col">
            <h4>{t("License")}</h4>
            <div className="social-links">
              <a href="https://www.tursab.org.tr/tr/ddsv" target="_blank">
                <img src={tursablogoen}/>
                
              </a>
            </div>
          </div>
          <div className="footer-col">
            <h4>{t("Follow Us")}</h4>
            <div className="social-links">
              <a href="https://www.facebook.com/07alanyatravel/">
                <FontAwesomeIcon icon={faFacebook} />
              </a>

              <a href="https://www.instagram.com/alanya07travel">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a href="www.youtube.com/07ALANYATRAVEL">
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
