import React from "react";
import "./Tours.css";
import Kapadokya from "../assets/kapadokya.jpg";
import Sapadere from "../assets/sapadere.jpg";
import Pamukkale from "../assets/pamukkale.jpg";
import Landof from "../assets/land of legends.jpg";
import Aspendos from "../assets/aspendos.jpg";
import Bodrum from "../assets/bodrum.png";
import Damlatas from "../assets/damlatascave.jpg";
import Dim from "../assets/dimriver.jpg";
import Dogu from "../assets/dogu.jpg";
import Efes from "../assets/efes.jpg";
import Fethiye from "../assets/fethiye.jpg";
import Kas from "../assets/kas.jpeg";
import Koprulu from "../assets/koprulucanyon.jpg";
import Kusadasi from "../assets/kusadasi.png";
import Marmaris from "../assets/marmaris.jpg";
import Side from "../assets/side.jpg";
import Dimcave from "../assets/dimcave.jpg";
import i18next from "i18next";
import { useTranslation } from 'react-i18next';
export default function Tours() {
  const { t, i18n } = useTranslation();
  return (
    <div className="widget-wrap">
      <h2>{t("TOURS")}</h2>

      <div className="gallery">
        <div>
          <img src={Kapadokya} alt="Kapadokya" />
          <h5>{t("Cappadocia")}</h5>
        </div>
        <div>
          <img src={Pamukkale} alt="pamukkale" />
          <h5>Pamukkale</h5>
        </div>
        <div>
          <img src={Landof} alt="landoflegends" />
          <h5>Land of Legends</h5>
        </div>
        <div>
          <img src={Aspendos} alt="aspendos" />
          <h5>Aspendos</h5>
        </div>
        <div>
          <img src={Side} alt="side" />
          <h5>Side</h5>
        </div>
        <div>
          <img src={Sapadere} alt="sapadere" />
          <h5>{t("Sapadere Canyon")}</h5>
        </div>
        <div>
          <img src={Dimcave} alt="dimcave" />
          <h5>{t("Dim Cave")}</h5>
        </div>
        <div>
          <img src={Damlatas} alt="damlatascave" />
          <h5>{t("Damlatas Cave")}</h5>
        </div>
        <div>
          <img src={Dim} alt="dimriver" />
          <h5>Dim Çayı</h5>
        </div>
        <div>
          <img src={Dogu} alt="easterntour" />
          <h5>{t("Eastern Turkey Tour")}</h5>
        </div>
        <div>
          <img src={Efes} alt="epheseus" />
          <h5>{t("Epheseus Ancient City")}</h5>
        </div>
        <div>
          <img src={Fethiye} alt="fethiye" />
          <h5>Fethiye</h5>
        </div>
        <div>
          <img src={Kas} alt="kas" />
          <h5>Kaş</h5>
        </div>
        <div>
          <img src={Koprulu} alt="canyon" />
          <h5>Köprülü Kanyon</h5>
        </div>
        <div>
          <img src={Kusadasi} alt="kusadasi" />
          <h5>Kuşadası</h5>
        </div>
        <div>
          <img src={Marmaris} alt="marmaris" />
          <h5>Marmaris</h5>
        </div>
        <div>
          <img src={Bodrum} alt="bodrum" />
          <h5>Bodrum</h5>
        </div>
      </div>
      <div></div>
    </div>
  );
}
