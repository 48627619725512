import { Layout } from "antd";
import React from "react";
import Navbar from "../Navbar";
import Footercomp from "../Footercomp";
import FloatingWp from "../Floatingwhatsapp";

const { Header, Content, Footer } = Layout;

export const MainLayout = ({ children }) => {
  return (
    <Layout>
      <Header >
      <Navbar />
      </Header>
      <Content >
        {children}
        <FloatingWp />
      </Content>
      <Footer style={{ backgroundColor: '#001529' }}>
        <Footercomp />
      </Footer>
    </Layout>
  );
};
