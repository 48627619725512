import React from "react";
import "./AboutUs.css";
import sitelogo from "../assets/Asset 4.png";
export default function AboutUs() {
  return (
    <div>
      <main className="main-grid">
        <img className="main-image" alt="sitelogo1" src={sitelogo} />

        <div className="main-text">
          <h2 className="section-title">About Us</h2>
          <p>
            As one of the first travel companies of Alanya, we have been serving
            our customers for 18 years with our experienced staff and premium
            quality vehicles.
          </p>

          <h2 className="section-title sub">What do we do? </h2>
          <p>
            We provide Airport Transfers, Rental car services, Culture Tours,
            Recreational Activities , VIP services for our customers.
          </p>
        </div>
      </main>
    </div>
  );
}
