import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import logo from "../assets/goldenlogo.png";
import ReactFlagsSelect from "react-flags-select";

import {
  HomeOutlined,
  CarOutlined,
  ContactsOutlined,
  MailOutlined,
} from "@ant-design/icons";

import { useTranslation } from "react-i18next";

export default function Navbar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const { t, i18n } = useTranslation();

  const [selected, setSelected] = useState("GB");

  const handleSelect = (code) => {
    const lng = code;

    i18n.changeLanguage(lng);
    setSelected(code);
  };

  return (
    <nav className="navbar">
      <div className="nav-container">
        <Link to="/">
          <img className="nav-logo" src={logo} alt="transfer-logo" />
        </Link>
       
        <ReactFlagsSelect
          className="menu-flags"
          selectedSize={20}
          optionsSize={19}
          placeholder=" "
          countries={["RU", "DE", "TR", "GB"]}
          showSelectedLabel={false}
          showOptionLabel={false}
          selected={selected}
          onSelect={handleSelect}
        />

        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link
              to="/"
              activeclassname="active"
              className="nav-links"
              onClick={handleClick}
            >
              <HomeOutlined /> &nbsp;{t("Home")}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/rentacar"
              activeclassname="active"
              className="nav-links"
              onClick={handleClick}
            >
              <CarOutlined />
              &nbsp; {t("Rent a Car")}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/tours"
              activeclassname="active"
              className="nav-links"
              onClick={handleClick}
            >
              <ContactsOutlined />
              &nbsp; {t("Tours")}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/contact"
              activeclassname="active"
              className="nav-links"
              onClick={handleClick}
            >
              <MailOutlined />
              &nbsp; {t("Contact Us")}
            </Link>
          </li>
        </ul>
        <div className="nav-icon" onClick={handleClick}>
          <i className={click ? "fa fa-times" : "fa fa-bars"}></i>
        </div>
      </div>
    </nav>
  );
}
