import React from "react";
import "./Reservationform.css";
import { useState, useEffect } from "react";
import {
  Select,
  DatePicker,
  Radio,
  InputNumber,
  Divider,
  Alert,
  Spin,
} from "antd";
import { IoCarSportSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
//transferlist
const arr = [
  { value: "Alanya", text: "Alanya" },
  { value: "Gazipaşa Airport(GZP)", text: "Gazipaşa Airport(GZP)" },
  { value: "Antalya Airport(AYT)", text: "Antalya Airport(AYT)" },
  { value: "Konaklı", text: "Konaklı" },
  { value: "Türkler", text: "Türkler" },
  { value: "Avsallar", text: "Avsallar" },
  { value: "Okurcalar", text: "Okurcalar" },
  { value: "Side", text: "Side" },
  { value: "Belek", text: "Belek" },
];

export default function Reservationform() {
  const [cPickupLocation, setcPickupLocation] = useState("");
  const [cDropoffLocation, setcDropoffLocation] = useState("");
  const [cReturnPickupLocation, setcReturnPickupLocation] = useState("");
  const [cReturnDropoffLocation, setcReturnDropoffLocation] = useState("");
  const [cDate, setcDate] = useState("");
  const [cReturnDate, setcReturnDate] = useState("");
  const [cAdult, setcAdult] = useState(1);
  const [cChildren, setcChildren] = useState("");
  const [cDirection, setcDirection] = useState("OneWay");
  const [Radiovalue, setRadiovalue] = useState("OneWay");
  const [formError, setformError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { Option } = Select;
  const [responseCheck, setResponseCheck] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setformError(null);

    if (!cPickupLocation) {
      setformError("Please select a pickup location");
      return;
    } else if (!cDropoffLocation) {
      setformError("Please select a drop off location");
      return;
    } else if (!cDate) {
      setformError("Please select a date");
      return;
    }
    setResponseCheck(true);
    setIsPending(true);
    navigate("/transferdetails", {
      state: {
        cDate,
        cPickupLocation,
        cDropoffLocation,
        cReturnPickupLocation,
        cReturnDropoffLocation,
        cReturnDate,
        cChildren,
        cAdult,
        cDirection,
      },
    });
    setIsPending(false);
  };

  useEffect(() => {
    if (responseCheck === "true") {
      setcDate("");
      setcPickupLocation("");
      setcDropoffLocation("");
      setcReturnPickupLocation("");
      setcReturnDropoffLocation("");
      setcReturnDate("");
      setcChildren("");
      setcAdult("");
      setcDirection("");
    }
  }, [responseCheck]);

  //HANDLERS
  const onRadioChange = (e) => {
    setRadiovalue(e.target.value);
  };
  const onSearch = (value) => {};
  const onChange = (value, dateString) => {
    setcDate(value);
    setcDate(dateString);
  };
  const onChange2 = (value, dateString) => {
    setcReturnDate(value);
    setcReturnDate(dateString);
  };

  const onOk = (value) => {};

  return (
    <div className="section-center">
      <h1>{t("MAKE YOUR RESERVATION")}</h1>
      <div className="container">
        <div className="row">
          <div className="booking-form">
            <form id="reservationform1" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <span className="form-label">{t("Pick Up Location:")}</span>
                    <Select
                      required
                      bordered={false}
                      size="large"
                      showSearch
                      suffixIcon={<IoCarSportSharp />}
                      className="form-control"
                      placeholder={t("From")}
                      optionFilterProp="children"
                      onChange={(value) =>
                        setcPickupLocation(value.toUpperCase())
                      }
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {arr.map((option, index) => (
                        <Option key={index} value={option.value}>
                          {option.text}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <span className="form-label">
                      {t("Drop Off Location:")}
                    </span>
                    <Select
                      required
                      bordered={false}
                      size="large"
                      showSearch
                      suffixIcon={<IoCarSportSharp />}
                      className="form-control"
                      placeholder={t("To")}
                      optionFilterProp="children"
                      onChange={(value) =>
                        setcDropoffLocation(value.toUpperCase())
                      }
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {arr.map((option, index) => (
                        <Option key={index} value={option.value}>
                          {option.text}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <span className="form-label">{t("Date and Time:")}</span>
                    <DatePicker
                      placeholder={t("Date")}
                      Timepicker
                      required
                      size="large"
                      className="form-control"
                      showTime
                      format="DD/MM/YYYY - HH:mm"
                      onChange={onChange}
                      onOk={onOk}
                    />

                    <Divider />
                  </div>
                </div>
              </div>

              {Radiovalue === "Return" && (
                <>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <span className="form-label">
                          {t("Pick Up Location:")}
                        </span>

                        <Select
                          bordered={false}
                          size="large"
                          showSearch
                          suffixIcon={<IoCarSportSharp />}
                          className="form-control"
                          placeholder={t("From")}
                          optionFilterProp="children"
                          onChange={(value) =>
                            setcReturnPickupLocation(value.toUpperCase())
                          }
                          onSearch={onSearch}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {arr.map((option, index) => (
                            <Option key={index} value={option.value}>
                              {option.text}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <span className="form-label">
                          {t("Drop Off Location:")}
                        </span>

                        <Select
                          bordered={false}
                          size="large"
                          showSearch
                          suffixIcon={<IoCarSportSharp />}
                          className="form-control"
                          placeholder={t("To")}
                          optionFilterProp="children"
                          onChange={(value) =>
                            setcReturnDropoffLocation(value.toUpperCase())
                          }
                          onSearch={onSearch}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {arr.map((option, index) => (
                            <Option key={index} value={option.value}>
                              {option.text}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <span className="form-label" required>
                          {t("Return Date and Time:")}
                        </span>
                        <DatePicker
                          placeholder={t("Date")}
                          size="large"
                          format="DD/MM/YYYY - HH:mm"
                          className="form-control"
                          showTime
                          onChange={onChange2}
                          onOk={onOk}
                        />
                        <Divider />
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="row">
                <div className="col-sm-2">
                  <div className="form-group">
                    <span className="form-label" required>
                      {t("Adults")}
                    </span>

                    <InputNumber
                      required
                      size="medium"
                      className="form-control"
                      min={1}
                      max={30}
                      defaultValue={1}
                      onChange={(value) => setcAdult(value)}
                    />
                  </div>
                </div>

                <div className="col-sm-2">
                  <div className="form-group">
                    <span className="form-label">{t("Children")}</span>
                    <InputNumber
                      className="form-control"
                      size="medium"
                      min={0}
                      max={30}
                      defaultValue={0}
                      onChange={(value) => setcChildren(value)}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="form-group">
                  <div className="form-checkbox" aria-required>
                    <Radio.Group
                      defaultValue={"OneWay"}
                      value={Radiovalue}
                      onChange={onRadioChange}
                    >
                      <Radio
                        defaultChecked={true}
                        onChange={(e) =>
                          setcDirection(e.target.value.toUpperCase())
                        }
                        value="OneWay"
                      >
                        <label>
                          <span></span>
                          {t("ONE WAY")}
                        </label>
                      </Radio>
                      <Radio
                        onChange={(e) =>
                          setcDirection(e.target.value.toUpperCase())
                        }
                        value="Return"
                      >
                        <label>
                          <span></span>
                          {t("RETURN")}
                        </label>
                      </Radio>
                    </Radio.Group>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-btn">
                    <button id="searchtransfer" className="submit-btn">
                      {t("Search")}
                    </button>
                    {formError && (
                      <Alert type="error" closable message={formError} />
                    )}
                    {isPending && <Spin />}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
