import React from "react";
import { Card } from "antd";
import c180 from "../assets/c180.png";
import caravelle from "../assets/caravelle.png";
import bmw2 from "../assets/bmw2.png";
import e200 from "../assets/e200.png";
import fluence from "../assets/fluence1.png";
import megane from "../assets/megane1.png";
import i20 from "../assets/i20.png";
import "./Rentacar.css";
import { FaWhatsapp } from "react-icons/fa";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export default function Rentacar() {
  const { t, i18n } = useTranslation();
  const { Meta } = Card;

  return (
    <div className="shell">
      <h2>{t("For details, please contact")}</h2>
      <p></p>

      <h4>
        <FaWhatsapp /> +90 533 159 93 07
      </h4>
      <div className="carousel-row">
        <div className="carousel-row--img">
          <img src={e200} className="hover-shadow" alt="e200" />

          <h3>MERCEDES E200</h3>
          <h5>{t("AUTOMATIC - DIESEL")}</h5>
        </div>
        <div className="carousel-row--img">
          <img src={c180} className="hover-shadow" alt="c180" />
          <h3 style={{ top: "10px" }}>MERCEDES C180</h3>
          <h5>{t("AUTOMATIC - DIESEL")}</h5>
        </div>
        <div className="carousel-row--img">
          <img
            style={{ paddingTop: "3px" }}
            src={bmw2}
            className="hover-shadow"
            alt="520d"
          />
          <h3 style={{ paddingTop: "3px" }}>BMW 520D</h3>
          <h5>{t("AUTOMATIC - DIESEL")}</h5>
        </div>
        <div className="carousel-row--img">
          <img src={i20} className="hover-shadow" alt="i20" />
          <h3>HYUNDAI i20</h3>
          <h5>{t("AUTOMATIC - DIESEL")}</h5>
        </div>
        <div className="carousel-row--img">
          <img src={fluence} className="hover-shadow" alt="fluence1" />
          <h3>RENAULT FLUENCE</h3>
          <h5>{t("AUTOMATIC - DIESEL")}</h5>
        </div>
        <div className="carousel-row--img">
          <img src={megane} className="hover-shadow" alt="megane2" />
          <h3>RENAULT MEGANE</h3>
          <h5>{t("AUTOMATIC - DIESEL")}</h5>
        </div>
        <div className="carousel-row--img">
          <img src={caravelle} className="hover-shadow" alt="caravelle3" />
          <h3>VOLKSWAGEN CARAVELLE</h3>
          <h5>{t("MANUAL - DIESEL")}</h5>
        </div>
      </div>
    </div>
  );
}
