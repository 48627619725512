import FloatingWhatsApp from "react-floating-whatsapp";
import Image from "../assets/facepp.png";

export default function FloatingWp() {
  const message = "Hello there! 🤝" + "\n" + "How can we help you?";

  return (
    <div className="App">
      <FloatingWhatsApp
        allowEsc="true"
        allowClickAway="true"
        phoneNumber="+905331599307"
        chatMessage={message}
        statusMessage="Usually replies in 2 minutes"
        accountName="07 ALANYA TRAVEL"
        avatar={Image}
      />
    </div>
  );
}
