import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyAJq64I_ikFeDmEPG2dWkT8pyIhvoQYeP8",
  authDomain: "alanyatravel-fefca.firebaseapp.com",
  projectId: "alanyatravel-fefca",
  storageBucket: "alanyatravel-fefca.appspot.com",
  messagingSenderId: "717535385381",
  appId: "1:717535385381:web:a31bcae87c0f05f8f5eccc",
  measurementId: "G-3QVHTTGT4Z"
};
//init firebase
firebase.initializeApp(firebaseConfig);

//init services

const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();

//timestamp

const timestamp = firebase.firestore.Timestamp;

export { projectFirestore, projectAuth, timestamp };
