import React from "react";
import { Card } from "antd";
import "./Home.css";
import "./Homequeries.css";
import Reservationform from "../components/Reservationform";
import Backgroundimg from "../assets/mercedes-benz.jpg";
import Merco1 from "../assets/merco1.png";
import Merco2 from "../assets/merco2.png";
import Merco3 from "../assets/merco3.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShieldHeart,
  faWandMagicSparkles,
  faPhone,
  faVanShuttle,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
const { Meta } = Card;

export default function Home() {
  const { t, i18n } = useTranslation();
  
  return (
    <div>
      <div className="home-container">
        <img className="main-img" src={Backgroundimg} alt="backgroundimg"></img>
        <div>
          <Reservationform />
        </div>
      </div>
      <section className="home-content-sidebar">
        <div className="one-third">
          <span className="circle">
            <FontAwesomeIcon icon={faShieldHeart} className="circle-icon" />
          </span>
          <p></p>
          <h3>{t("RELIABLE TRANSFERS")}</h3>
        </div>
        <div className="one-third">
          <span className="circle">
            <FontAwesomeIcon
              icon={faWandMagicSparkles}
              className="circle-icon"
            />
          </span>
          <p></p>
          <h3>{t("BOOKING FLEXIBILITY")}</h3>
        </div>
        <div className="one-third">
          <span className="circle">
            <FontAwesomeIcon icon={faPhone} className="circle-icon" />
          </span>
          <p></p>
          <h3>{t("24H CUSTOMER SERVICE")}</h3>
        </div>
        <div className="one-third">
          <span className="circle">
            <FontAwesomeIcon icon={faUserTie} className="circle-icon" />
          </span>
          <p></p>
          <h3>{t("PROFESSIONAL DRIVERS")}</h3>
        </div>
        <div className="one-third">
          <span className="circle">
            <FontAwesomeIcon icon={faVanShuttle} className="circle-icon" />
          </span>
          <p></p>
          <h3>{t("PREMIUM VEHICLES")}</h3>
        </div>
      </section>
      <div className="black-cta">
        <h1>{t("TRANSFER TYPES")}</h1>
      </div>
      <div className="card-container">
        <Card
          hoverable
          className="card-styles"
          cover={<img alt="transfertype1" src={Merco1} />}
        >
          <Meta title={t("VIP Car")} description={t("1-4 People")} />
        </Card>
        <Card
          hoverable
          className="card-styles"
          cover={<img alt="transfertype2" src={Merco2} />}
        >
          <Meta title="Mercedes VITO" description={t("1-6 People")} />
        </Card>
        <Card
          hoverable
          className="card-styles"
          cover={<img alt="transfertype3" src={Merco3} />}
        >
          <Meta title="Mercedes Sprinter" description={t("1-17 People")} />
        </Card>
      </div>
    </div>
  );
}
