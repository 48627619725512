import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { projectFirestore } from "../firebase/config";
import { useFirestore } from "../hooks/useFirestore";
import { Table } from "antd";
import "./Listtransfers.css";
import Trashcan from "../assets/trashcan1.svg";
import { useLogout } from "../hooks/useLogout";

export default function Listtransfers({ transfers }) {
  const { id } = useParams();
  const { deleteDocument, response } = useFirestore("transfers");
  const [transfer, setTransfer] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(false);

  const { logout } = useLogout();

  useEffect(() => {
    setIsPending(true);
    projectFirestore
      .collection("transfers")
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setIsPending(false);
          setTransfer(doc.data());
        } else {
          setIsPending(false);
          setError("Transfer not found");
        }
      });
  }, [id]);

  const columns = [
    {
      title: "Title",
      width: 70,
      fontWeight: "200",
      dataIndex: "cTitle",
      key: "1",
      fixed: "left",
      className: "table-column",
    },

    {
      className: "table-column",
      title: "Name",
      width: 150,
      dataIndex: "cName",
      key: "2",
      fixed: "left",
    },
    {
      className: "table-column",
      title: "Surname",
      width: 150,
      dataIndex: "cSurname",
      key: "3",
      fixed: "left",
    },
    {
      className: "table-column",
      title: "From",
      dataIndex: "cPickupLocation",
      key: "4",
      width: 150,
    },

    {
      className: "table-column",
      title: "To",
      dataIndex: "cDropoffLocation",
      key: "5",
      width: 150,
    },
    {
      className: "table-column",
      title: "Date&Time",
      dataIndex: "cDate",
      key: "6",
      width: 150,
    },

    {
      className: "table-column",
      title: "Adult",
      dataIndex: "cAdult",
      key: "7",
      width: 70,
    },
    {
      className: "table-column",
      title: "Children",
      dataIndex: "cChildren",
      key: "8",
      width: 85,
    },
    {
      className: "table-column",
      title: "Transfer Type",
      dataIndex: "cDirection",
      key: "9",
      width: 100,
    },
    {
      className: "table-column",
      title: "Vehicle",
      dataIndex: "cTransfertype",
      key: "10",
      width: 150,
    },
    {
      className: "table-column",
      title: "Adress",
      dataIndex: "cAdress",
      key: "11",
      width: 200,
    },
    {
      className: "table-column",
      title: "Reservation Code",
      dataIndex: "cReservationcode",
      width: 110,
      key: "12",
    },
    {
      className: "table-column",
      title: "Reservation Date",
      dataIndex: "datetime",
      width: 110,
      key: "13",
    },
    {
      title: "Action",
      key: "14",
      fixed: "right",
      width: 100,
      render: (transfer) => (
        <img
          className="table-icon"
          src={Trashcan}
          alt=""
          onClick={() => deleteDocument(transfer.id)}
        />
      ),
    },
  ];

  return (
    <div>
      <button className="logoutbutton" onClick={logout}>
        Logout
      </button>
      {transfers.map((transfer) => (
        <div key={transfer.id}></div>
      ))}

      <Table
        rowKey={(record) => record.id}
        columns={columns}
        bordered
        expandable={{
          expandedRowRender: (record) => (
            <p
              style={{
                margin: 0,
              }}
            >
              <h5>
                <strong>Email:</strong> &nbsp; {record.cEmail} &nbsp;&nbsp;
                <strong>Phone:</strong> &nbsp; {record.cPhone}&nbsp;&nbsp;
                <strong>Flight Number:</strong> &nbsp;{record.cFlightnumber}{" "}
                &nbsp;&nbsp;
                <strong> Return Pickup:</strong>&nbsp;{" "}
                {record.cReturnPickupLocation} &nbsp;&nbsp;
                <strong> Return Drop off:</strong>&nbsp;{" "}
                {record.cReturnDropoffLocation}&nbsp;&nbsp;
                <strong>Return Date:</strong>&nbsp; {record.cReturnDate}
              </h5>
            </p>
          ),
        }}
        dataSource={transfers}
        scroll={{
          x: 1500,
        }}
        sticky
      />
    </div>
  );
}
