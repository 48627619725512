import React from "react";
import "./SuccessfulTransfer.css";
import { Result, Button, Divider } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
export default function SuccessfulTransfer() {
  const navigate = useNavigate();

  const { state } = useLocation();
  const {
    cReservationcode,
    cPickupLocation,
    cDropoffLocation,
    cDate,
    cChildren,
    cAdult,
    cDirection,
    cReturnPickupLocation,
    cReturnDropoffLocation,
    cReturnDate,
    cTitle,
    cTransfertype,
    cName,
    cSurname,
    cEmail,
    cFlightnumber,
    cPhone,
    cAdress,
    transferPrice,
  } = state;
  const { t, i18n } = useTranslation();
  const handleClick = () => {
    navigate("/");
  };
  return (
    <div className="transfer-success">
      <Result
        status="success"
        title={
          <Divider>
            {t(
              "Your reservation has been successfully made.You will be contacted for the confirmation of your transfer."
            )}
            <p />
            <p>{t("Your Reservation Number is:")}</p>

            <strong>
              <h1> {cReservationcode}</h1>
            </strong>

            <h3>{t("RESERVATION SUMMARY")}</h3>
            <h5>
              <strong>{t("Title")}:</strong> {cTitle}
            </h5>
            <h5>
              <strong>{t("First Name")}:</strong>
              {cName}
            </h5>
            <h5>
              <strong>{t("Last Name")}:</strong> {cSurname}
            </h5>
            <h5>
              <strong>{t("Pick Up Location:")}:</strong> {cPickupLocation}
            </h5>
            <h5>
              <strong>{t("Drop Off Location:")}:</strong> {cDropoffLocation}
            </h5>
            <h5>
              <strong>{t("Children")}:</strong> {cChildren}
            </h5>
            <h5>
              <strong>{t("Date")}:</strong> {cDate}
            </h5>
            <h5>
              <strong>{t("Direction")}:</strong> {cDirection}
            </h5>
            <h5>
              <strong>{t("Transfer Type")}:</strong> {cTransfertype}
            </h5>
            <h5>
              <strong>{t("Return Pick up Location")}:</strong>{" "}
              {cReturnPickupLocation}
            </h5>
            <h5>
              <strong>{t("Return Drop off Location")}:</strong>{" "}
              {cReturnDropoffLocation}
            </h5>
            <h5>
              <strong>{t("Return Date and Time:")}</strong> {cReturnDate}
            </h5>
            <h5>
              <strong>{t("Email")}</strong> {cEmail}
            </h5>
            <h5>
              <strong>{t("Phone Number")}:</strong> {cPhone}
            </h5>
            <h5>
              <strong>{t("Flight Number")}:</strong> {cFlightnumber}
            </h5>
            <h5>
              <strong>{t("Detailed Pickup Place")}:</strong> {cAdress}
            </h5>
            <h5>
              <strong>{t("Price")}:</strong> {transferPrice}
            </h5>
          </Divider>
        }
        extra={[
          <Button
            type="primary"
            key="reservation-successful"
            onClick={handleClick}
          >
            {t("Go back to Home Page")}
          </Button>,
        ]}
      />
    </div>
  );
}
